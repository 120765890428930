.imgIcon-layanan {
    height: "42,58px";
    width:"65,83px";
}

/* .beranda {
    width: '100%';
    max-height: '100%';
    background-image: url('../images/auth-layer.png') ;
    background-size: 'cover';
} */

/* .beranda p {
    text-align: center;
    font-size: 48px;
    line-height: 49px;
    font-weight: 400;
} */
