/**=====================
    30. Data-table CSS Start
==========================**/
.datatable-react {
  .form-control, .react-bootstrap-table {
    margin-bottom: 30px;
  }
  .react-bootstrap-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    .table-bordered {
      td, th {
        border-color: #ddd;
      }
    }
    table {
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #f9f9f9;
            td {
              &:first-child {
                background-color: #f6f6f6;
              }
            }
            &:hover {
              td {
                background-color: #f6f6f6;
              }
            }
          }
          &:nth-child(even) {
            td {
              &:first-child {
                background-color: #fafafa;
              }
            }
            &:hover {
              td {
                background-color: #f6f6f6;
                &:first-child {
                  background-color: #eaeaea;
                }
              }
            }
          }
        }
      }
    }
  }
}
div{
  &.dt-button-background{
    display: none !important;
  }
}
.dataTables_wrapper{
  &.no-footer {
    .dataTables_scrollBody {
      border-bottom: 1px solid $light-gray;
    }
  }
  padding: 0;
  button{
    font-weight: 400;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: $white;
  }
  width: 100%;
  &+.dataTables_wrapper{
    margin-top: 20px;
  }
  .dataTables_paginate{
    margin-left: 15px !important;
    border: 1px solid $light-color;
    border-radius: 0.25rem;
    padding-top: 0;
    .paginate_button{
      margin: 0;
      &.current,&:active{
        background: $primary-color;
        color: $white !important;
        box-shadow: none;
      }
      &:hover{
        border:1px solid $primary-color;
        color: $dark-color !important;
        background: transparent !important;
      }
    }
  }
  .dataTables_length{
    margin-bottom: 30px;
    select{
      border-color: $light-color;
      color: $dark-color;
      padding: 0 10px;
      margin: 0 10px;
      height:2.7142em;
    }
  }
  table.dataTable{
    border:1px solid $light-semi-gray;
    thead{
      th,td{
        border-bottom:2px solid $light-semi-gray;
      }
    }
    th,td{
      padding: 0.75rem;
    }
  }
  .dataTables_filter{
    margin-bottom: 25px;
    margin-left: 15px;
    input[type="search"]{
      border: 1px solid $light-semi-gray;
      padding: 0 10px;
      margin-left: 10px;
      height: 37px;
      border-radius: 0;
    }
  }
}
.dataTables_scrollHeadInner{
  width: 100% !important;
}
table{
  .fixedHeader-floating {
    position: fixed!important;
    background-color: $white;
  }
  .box{
    >div{
      padding: 5px 15px;
      background-color: $primary-color;
      display: inline-block;
      color: $white;
      border-radius: 5px;
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
   &.dataTable{
     &.fixedHeader-locked,&.fixedHeader-floating{
       width:  calc(100vw - 250px)  !important;
       max-width: calc(100vw - 250px) !important;
       overflow: hidden !important;
       right: 0 !important;
       z-index: 99;
       left: 260px !important;
     }
      &:not(.fixedHeader-locked){
        &:not(.fixedHeader-floating){
          width: 100% !important;
        }
      }
   }
  &.dataTable{
    border-collapse: collapse!important;
    margin-top: 0 !important;
    thead{
      .sorting,.sorting_asc,.sorting_desc,.sorting_asc_disabled,.sorting_desc_disabled{
        &:before,&:after{
          bottom: 12px !important;
        }
      }
    }
    input,select{
      border: 1px solid $light-semi-gray;
      padding: 0 10px;
      height: 37px;
    }
  }
}

.toolbar{
  padding: 5px 15px;
  background-color: $primary-color;
  display: inline-block;
  color: $white;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}
code{
  &.option{
    color: $danger-color !important;
  }
  &.api{
    color: $success-color !important;
  }
}

// Datatable extension
.dt-ext{
  .dataTables_wrapper{
    .page-item{
      &.active {
        .page-link {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
      }
    }

    .dataTables_paginate{
      border: none;
      .paginate_button{
        padding: 0;
        border: none;
        &.disabled,&.hover,&.active{
            border: none !important;
        }
      }
      .page-link{
        margin-left: 0px;
        color: $primary-color;
      }
    }
    button.dt-button, div.dt-button, a.dt-button, button.dt-button:focus:not(.disabled), div.dt-button:focus:not(.disabled), a.dt-button:focus:not(.disabled), button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled), div.dt-button:active:not(.disabled), div.dt-button.active:not(.disabled), a.dt-button:active:not(.disabled), a.dt-button.active:not(.disabled){
      background-color: $primary-color;
      border-color:$primary-color;
      border-radius: 2px;
      color: $white;
      background-image: none;
      font-size: 14px;
    }
    button.dt-button.btn-success, div.dt-button.btn-success, a.dt-button.btn-success{
      background-color: $success-color;
      border-color:$success-color;
    }
    button.dt-button.btn-secondary, div.dt-button.btn-secondary, a.dt-button.btn-secondary{
      background-color: $secondary-color;
      border-color:$secondary-color;
    }
    button.dt-button.btn-danger, div.dt-button.btn-danger, a.dt-button.btn-danger{
      background-color: $danger-color;
      border-color:$danger-color
    }
    table.dataTable th.focus, table.dataTable td.focus{
      outline: 3px solid $primary-color;
    }
    table.dataTable.display tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1{
      background-color: lighten($primary-color , 20%);

    }
    table.dataTable.stripe tbody > tr.odd.selected, table.dataTable.stripe tbody > tr.odd > .selected, table.dataTable.display tbody > tr.odd.selected, table.dataTable.display tbody > tr.odd > .selected{
      background-color: lighten($primary-color , 20%);
    }
    table.dataTable.display tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1{
      background-color: lighten($primary-color , 20%);
    }
    table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected{
      background-color: lighten($primary-color , 20%);
    }
  }
}

td.highlight {
  background-color: $light-color !important;
}
td.details-control {
  background: url('/assets/images/details_open.png') no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  background: url('/assets/images/details_close.png') no-repeat center center;
}
.dataTables_scroll{
  &~.dataTables_paginate{
    margin-top: 20px !important;
  }
}
.product-table{
  th{
    &:last-child{
      min-width: 120px;
    }
  }
  h6{
    font-weight: 600;
    color: $dark-color;
  }
}
table{
  &.dataTable{
    &.display {
      tbody {
        tr{
          &.odd {
            > .sorting_1{
              background-color: $light;
            }
          }
        }
      }
    }
  }
}
/**=====================
     30. Data-table CSS Ends
==========================**/
